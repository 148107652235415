import { Icons, Modal, ModalContent } from '@components';
import * as messages from './messages';
import { RefObject } from 'react';

const ModalDqe = ({
  setIsToBeCorrected = () => null,
  setIsDqeErrorModalVisible = () => null,
  getIsDqeErrorModalVisible = () => false,
  getLastDqeEmailTested = () => '',
  emailInputRef = () => null,
}: {
  setIsToBeCorrected?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDqeErrorModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  getIsDqeErrorModalVisible?: () => boolean;
  getLastDqeEmailTested?: () => string;
  emailInputRef: () => RefObject<HTMLInputElement> | null;
}): React.ReactElement => {
  return (
    <Modal isVisible={getIsDqeErrorModalVisible()}>
      <ModalContent
        title={
          messages.MODAL_DQE_TITLE_EMAIL_SAISI +
          getLastDqeEmailTested() +
          messages.MODAL_DQE_TITLE_EST_PAS_RECONNU
        }
        icon={<Icons.WarningIcon />}
        text={messages.MODAL_DQE_TEXT}
        primaryButtonLabel={messages.MODAL_DQE_PRIMARY_BUTTON_TEXT}
        onPrimaryButtonClick={() => {
          setIsDqeErrorModalVisible(false);
        }}
        secondaryButtonLabel={messages.MODAL_DQE_SECONDARY_BUTTON_TEXT}
        onSecondaryButtonClick={() => {
          setIsDqeErrorModalVisible(false);
          setIsToBeCorrected(true);
          emailInputRef()?.current?.focus();
        }}
      ></ModalContent>
    </Modal>
  );
};

export default ModalDqe;
