import * as messages from './messages';
import { Icons, Input, Select, Title } from '@components';
import {
  FormWrapper,
  ItemWrapper,
  StyledTextTooltip,
  StyledTitleTooltip,
  StyledTooltip,
  TitleWrapper,
  WrapperInputCity,
  WrapperInputFixPhone,
  WrapperInputMobilePhone,
  WrapperInputZipCd,
  WrapperRowInputs,
} from './styles';
import { Controller, useFormContext } from 'react-hook-form';
import { selectPerson, useAppSelector, personTypes } from '@store';
import {
  addressRegExp,
  fixPhoneRegExp,
  phoneMask,
  mobilePhoneRegExp,
  zipCodeMask,
  isPersonFieldDisabled,
} from '@utils';
import { EvidenceType, MobileKeyboardType } from '@types';
import useInformationsForm from '../useInformationsForm';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { CheckEmailValideAndDqeProps, useValidateInput } from '@hooks';

export interface IProps {
  setAddressCity: Dispatch<SetStateAction<string>>;
  checkEmailValideAndDqe: (args: CheckEmailValideAndDqeProps) => void;
  isEmailValideOrToBeCorrected: (mail: string) => boolean;
}

const Contact: React.FC<IProps> = ({
  setAddressCity,
  checkEmailValideAndDqe,
  isEmailValideOrToBeCorrected,
}) => {
  const { control, getValues, trigger } = useFormContext();
  const emailInputRef = useRef<HTMLInputElement>(null);

  const {
    listCitiesZipCd,
    isCitiesByZipCdReady,
    onValidateInputZipCd,
    isCityEnabled,
    isCitiesByZipCdLoading,
  } = useInformationsForm({
    role: personTypes.RoleCd.BORROWER,
    setAddressCity,
  });

  const persons = useAppSelector(selectPerson);
  const person = persons?.emprunteur;
  const suffixRole = '_' + personTypes.RoleCd.BORROWER;

  const { shouldValidateInput } = useValidateInput();

  useEffect(() => {
    if (!persons?.emprunteur) {
      return;
    }
    if (persons?.emprunteur?.billingAddressInformation?.zipCd) {
      shouldValidateInput(
        'inputZipCd' + suffixRole,
        persons?.emprunteur?.billingAddressInformation?.zipCd
      );
    }
    shouldValidateInput(
      'inputAddressLineOne' + suffixRole,
      persons?.emprunteur?.billingAddressInformation?.streetAddress
    );
    shouldValidateInput(
      'inputAddressLineTwo' + suffixRole,
      persons?.emprunteur?.billingAddressInformation?.additionalAddress
    );
    shouldValidateInput(
      'inputEmail' + suffixRole,
      persons?.emprunteur?.contactInformation?.email
    );
    shouldValidateInput(
      'inputMobilePhoneNumber' + suffixRole,
      persons?.emprunteur?.contactInformation?.mobilePhoneNumber
    );
    shouldValidateInput(
      'inputFixPhoneNumber' + suffixRole,
      persons?.emprunteur?.contactInformation?.fixPhoneNumber
    );
  }, [persons]);

  useEffect(() => {
    if (!persons?.emprunteur || !getValues('inputZipCd' + suffixRole)) {
      return;
    }
    if (persons?.emprunteur?.billingAddressInformation?.city) {
      shouldValidateInput(
        'inputCity' + suffixRole,
        persons?.emprunteur?.billingAddressInformation?.city
      );
    }
  }, [getValues('inputZipCd' + suffixRole)]);

  return (
    <FormWrapper>
      <TitleWrapper>
        <Title level={2}>{messages.CONTAINER_TITLE}</Title>
      </TitleWrapper>

      <ItemWrapper>
        <Controller
          control={control}
          defaultValue={person?.billingAddressInformation?.streetAddress}
          name={'inputAddressLineOne' + suffixRole}
          rules={{
            required: true,
            pattern: addressRegExp,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Input
              name={name}
              label={messages.INPUT_ADDRESS_LINE_ONE}
              type="text"
              value={value}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              onChange={onChange}
              maxLength={30}
              isSubmitted={isSubmitted}
              isDisabled={isPersonFieldDisabled(
                person?.billingAddressInformation?.streetAddress,
                person,
                EvidenceType.JDDO
              )}
            />
          )}
        />
      </ItemWrapper>

      <ItemWrapper>
        <Controller
          control={control}
          name={'inputAddressLineTwo' + suffixRole}
          defaultValue={person?.billingAddressInformation?.additionalAddress}
          rules={{
            required: false,
            pattern: addressRegExp,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Input
              name={name}
              label={messages.INPUT_ADDRESS_LINE_TWO}
              type="text"
              value={value}
              isTouched={isTouched}
              onBlur={onBlur}
              inputRef={ref}
              onChange={onChange}
              isValid={!invalid}
              maxLength={30}
              isSubmitted={isSubmitted}
            />
          )}
        />
      </ItemWrapper>

      <WrapperRowInputs>
        <WrapperInputZipCd>
          <Controller
            control={control}
            name={'inputZipCd' + suffixRole}
            defaultValue={person?.billingAddressInformation?.zipCd}
            rules={{
              required: true,
              validate: onValidateInputZipCd,
            }}
            render={({
              field: { onChange, value, name, onBlur, ref },
              fieldState: { isTouched, invalid },
              formState: { isSubmitted },
            }) => (
              <Input
                inputMode={MobileKeyboardType.NUMERIC}
                name={name}
                label={messages.INPUT_ZIP_CODE}
                value={value}
                isTouched={isTouched}
                isValid={!invalid}
                isSubmitted={isSubmitted}
                onBlur={onBlur}
                inputRef={ref}
                onChange={onChange}
                mask={zipCodeMask}
                maskChar=""
                maxLength={5}
                error={
                  isTouched && invalid && !isCitiesByZipCdLoading
                    ? messages.ERROR_ZIPCD
                    : ''
                }
                isLoading={isCitiesByZipCdLoading}
                isDisabled={
                  !isCityEnabled &&
                  isPersonFieldDisabled(
                    person?.billingAddressInformation?.zipCd,
                    person,
                    EvidenceType.JDDO
                  )
                }
              />
            )}
          />
        </WrapperInputZipCd>

        <WrapperInputCity>
          <Controller
            control={control}
            name={'inputCity' + suffixRole}
            defaultValue={person?.billingAddressInformation?.city ?? ''}
            rules={{
              required: true,
            }}
            render={({
              field: { onChange, value, name, onBlur, ref },
              fieldState: { isTouched, invalid },
              formState: { isSubmitted },
            }) => (
              <Select
                name={name}
                label={messages.INPUT_CITY}
                options={listCitiesZipCd ?? []}
                value={value}
                isTouched={isTouched}
                isValid={!invalid}
                onBlur={onBlur}
                inputRef={ref}
                isDisabled={
                  (!isCityEnabled &&
                    isPersonFieldDisabled(
                      person?.billingAddressInformation?.city,
                      person,
                      EvidenceType.JDDO
                    )) ||
                  !isCitiesByZipCdReady ||
                  !listCitiesZipCd ||
                  listCitiesZipCd?.length <= 1
                }
                onChange={onChange}
                isSubmitted={isSubmitted}
                hasSearch
              />
            )}
          />
        </WrapperInputCity>
      </WrapperRowInputs>

      <ItemWrapper>
        <Controller
          control={control}
          name={'inputEmail' + suffixRole}
          defaultValue={person?.contactInformation?.email}
          rules={{
            required: true,
            validate: isEmailValideOrToBeCorrected,
          }}
          render={({
            field: { onChange, value, name, onBlur },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => {
            return (
              <Input
                inputMode={MobileKeyboardType.EMAIL}
                name={name}
                label={messages.INPUT_EMAIL}
                placeholder={messages.PLACEHOLDER_EMAIL}
                value={value}
                isTouched={isTouched}
                isValid={!invalid}
                onBlur={() => {
                  onBlur();
                  checkEmailValideAndDqe({
                    newMailToTest: value,
                    emailInputRef,
                  });
                }}
                onFocus={() => {
                  trigger(name);
                }}
                inputRef={emailInputRef}
                onChange={onChange}
                isSubmitted={isSubmitted}
                error={isTouched && invalid ? messages.ERROR_EMAIL : ''}
              />
            );
          }}
        />
      </ItemWrapper>

      <WrapperRowInputs>
        <WrapperInputMobilePhone>
          <Controller
            control={control}
            name={'inputMobilePhoneNumber' + suffixRole}
            defaultValue={person?.contactInformation?.mobilePhoneNumber}
            rules={{
              required: !getValues('inputFixPhoneNumber' + suffixRole),
              pattern: mobilePhoneRegExp,
            }}
            render={({
              field: { onChange, value, name, onBlur, ref },
              fieldState: { isTouched, invalid },
              formState: { isSubmitted },
            }) => (
              <Input
                inputMode={MobileKeyboardType.TEL}
                name={name}
                label={messages.INPUT_MOBILE_PHONE_NUMBER}
                placeholder={messages.PLACEHOLDER_MOBILE_PHONE_NUMBER}
                value={value}
                isTouched={isTouched}
                isValid={!invalid}
                onBlur={onBlur}
                inputRef={ref}
                onChange={(value) => {
                  onChange(value);
                  trigger('inputFixPhoneNumber' + suffixRole);
                }}
                mask={phoneMask}
                maskChar=" "
                isSubmitted={isSubmitted}
                error={
                  isTouched &&
                  !(value || getValues('inputFixPhoneNumber' + suffixRole))
                    ? messages.ERROR_PHONE_NUMBER
                    : isTouched && invalid
                    ? messages.ERROR_MOBILE_PHONE_NUMBER
                    : ''
                }
              />
            )}
          />
        </WrapperInputMobilePhone>

        <WrapperInputFixPhone>
          <Controller
            control={control}
            name={'inputFixPhoneNumber' + suffixRole}
            defaultValue={person?.contactInformation?.fixPhoneNumber}
            rules={{
              required: !getValues('inputMobilePhoneNumber' + suffixRole),
              pattern: fixPhoneRegExp,
            }}
            render={({
              field: { onChange, value, name, onBlur, ref },
              fieldState: { isTouched, invalid },
              formState: { isSubmitted },
            }) => (
              <Input
                inputMode={MobileKeyboardType.TEL}
                name={name}
                label={messages.INPUT_FIX_PHONE_NUMBER}
                placeholder={messages.PLACEHOLDER_FIX_PHONE_NUMBER}
                value={value}
                isTouched={isTouched}
                isValid={!invalid}
                onBlur={onBlur}
                inputRef={ref}
                onChange={(value) => {
                  onChange(value);
                  trigger('inputMobilePhoneNumber' + suffixRole);
                }}
                mask={phoneMask}
                maskChar=" "
                isSubmitted={isSubmitted}
                error={
                  isTouched &&
                  invalid &&
                  (value || getValues('inputMobilePhoneNumber' + suffixRole))
                    ? messages.ERROR_FIX_PHONE_NUMBER
                    : ''
                }
              />
            )}
          />
        </WrapperInputFixPhone>
      </WrapperRowInputs>

      <StyledTooltip
        skin="advice"
        hasArrowDown={false}
        icon={Icons.InfoOutlinesIcon}
        isVisible={true}
      >
        <StyledTitleTooltip level={2}>
          {messages.TOOLTIP_CONTACT_TITLE}
        </StyledTitleTooltip>
        <StyledTextTooltip>
          {messages.TOOLTIP_PHONE_ADD}
          <br />
          {messages.TOOLTIP_MOBILE_PHONE_NOTE}
        </StyledTextTooltip>
      </StyledTooltip>
    </FormWrapper>
  );
};

export default Contact;
