import { useEffect, useState } from 'react';
import {
  IEvidenceType,
  IEvidenceBlock,
  IOcr,
  BorderColorType,
  UploadType,
  ImageStatus,
  IOption,
  FileStatus,
  IFile,
  IImagesFile,
  StatusCd,
  AnalyseDocument,
  EvidenceType,
} from '@types';
import {
  Card,
  Select,
  Loader,
  Modal,
  InformationTooltip,
  RadioButton,
} from '@components';
import {
  usePersistingState,
  useBorrowers,
  useDetectMobileDevice,
} from '@hooks';
import {
  StyledCard,
  CardHeader,
  StyledSubtitle,
  StyledMandatory,
  WrapperSelect,
  WrapperCheckBox,
  WrapperCheckBoxHasHisRib,
  BlockRadioButton,
  BlockRadio,
  WrapperSent,
  StyledSentIcon,
  StyledSentText,
  StyledCrossIcon,
  WrapperCardDocumentSent,
  TextParagraph,
  StyledSubtitleUploading,
} from './styles';
import * as messages from './messagesBlockOcr';
import * as messagesDocuments from './messages';
import EvidenceUpload from './EvidenceUpload';
import BlockStatus from './types';
import useModalOcr, { IUseModalOcrOutput } from './Modal/useModalOcr';
import {
  personTypes,
  selectConfiguration,
  selectPerson,
  useAppSelector,
  useSendAnalyseDocumentMutation,
  useSendDeleteDocumentMutation,
  useSendDocumentMutation,
} from '@store';
import {
  addImage,
  getRenamedFile,
  getUniqueId,
  getUniqueName,
  uploadFile,
} from '@utils';
import { GenericError } from './Modal/alerts';
import { useDeleteDocumentCancelMutation } from 'store/souscriptionApi/souscriptionApiSlice';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  CrossCircleOutlinesIcon,
  FilePayslipSymphonyIcon,
  FinanceSymphonyIcon,
  HomeSymphonyIcon,
  PersonSymphonyIcon,
} from '@icons';

export interface IProps {
  ocrParameters: IOcr;
  block: IEvidenceBlock;
  onFormValidation?: (isValid: boolean) => void;
  onFileUpload: (block: IEvidenceBlock) => void;
  borrowerType: string;
  onValidate: (block: IEvidenceBlock) => void;
  isAnalyseSending: boolean;
  setIsAnalyseSending: React.Dispatch<React.SetStateAction<boolean>>;
  isDocumentUploaded?: boolean;
  doClose?: boolean;
  onExpanding?: () => void;
}

const BlockOcr: React.FC<IProps> = ({
  ocrParameters,
  block,
  onFormValidation,
  onFileUpload,
  borrowerType,
  onValidate,
  isAnalyseSending,
  setIsAnalyseSending,
  isDocumentUploaded = false,
  doClose = false,
  onExpanding = () => null,
}) => {
  const { hasCoborrower } = useBorrowers();
  const [evidenceSubType, setEvidenceSubType] = usePersistingState<
    IEvidenceType | undefined
  >(
    `type-${block.name}-${borrowerType}`,
    block.evidenceTypes.length === 1 ? block.evidenceTypes[0] : undefined
  );
  const [hasError, setError] = usePersistingState('has-document-error', '');
  const [isNoRibSepa, setNoRibSepa] = useState(false);
  const [clientHasHisRib, setClientHasHisRib] = usePersistingState(
    'clientHasHisRib',
    true
  );
  const [ribOwner, setRibOwner] = usePersistingState<string | undefined>(
    `rib-owner-${block.name}-${borrowerType}`,
    !hasCoborrower() && block.name === EvidenceType.JBAN
      ? personTypes.RoleCd.BORROWER
      : undefined
  );
  const [triggerSendDocument, responseSendDocument] = useSendDocumentMutation();
  const [triggerSendDeleteDocument, responseSendDeleteDocument] =
    useSendDeleteDocumentMutation();
  const [processedImages, setProcessedImages] = usePersistingState<
    IImagesFile[]
  >(`processedImages-${block.name}-${borrowerType}`, []);

  const [triggerDeleteDocumentCancel] = useDeleteDocumentCancelMutation();

  const [uploadedFiles, setUploadedFiles] = usePersistingState<IFile[]>(
    `uploadedFiles-${block.name}-${borrowerType}`,
    []
  );

  const [isMaxFilesLimitReached, setIsMaxFilesLimitReached] = useState(false);

  const [blockStatus, setBlockStatus] = usePersistingState(
    `blockStatus-${block.name}-${borrowerType}`,
    BlockStatus.NOT_VALIDATED
  );

  const { isMobileScreenSize } = useDetectMobileDevice();

  const [isExpanded, setIsExpanded] = usePersistingState(
    `blockStatus-${block.name}-is-expanded`,
    false
  );

  const [isClosed, setIsClosed] = useState(false);

  const person = useAppSelector(selectPerson);
  const { ocrKo } = person?.emprunteur ?? '';
  const isEnded = blockStatus !== BlockStatus.NOT_VALIDATED || !!ocrKo;

  const isExpandable = isMobileScreenSize();

  useEffect(() => {
    if (isDocumentUploaded) {
      handleValidate(block);
    }
  }, [isDocumentUploaded]);

  useEffect(() => {
    const closing =
      (isExpandable && doClose && !(uploadedFiles ?? [])[0] && !isEnded) ||
      (isExpandable && !isExpanded);
    if (!isExpandable || (isClosed && closing && !isExpanded)) {
      return;
    }
    setIsClosed(closing);
    if (closing) {
      setIsExpanded(false);
    }
  }, [isExpandable, doClose, uploadedFiles, isEnded, isExpanded]);

  const getRibOwner = (): string => {
    return ribOwner ? getRibOwnerCJ(ribOwner) : borrowerType;
  };

  const getRibOwnerCJ = (value: string): string => {
    return value === personTypes.RoleCd.COMPTE_JOINT
      ? personTypes.RoleCd.BORROWER
      : value;
  };

  const handleChange = (value: string) => {
    setProcessedImages([]);
    setOcrData(undefined);
    deleteAllFiles();
    setEvidenceSubType(block.evidenceTypes.find((item) => item.id === value));
  };

  const handleChangeJBAN = (value: string) => {
    if (getRibOwner() !== getRibOwnerCJ(value)) {
      setProcessedImages([]);
      setOcrData(undefined);
      deleteAllFiles();
    }
    setRibOwner(value);
  };

  const onFileDelete = (id: string) => {
    // Activating the validation button upon the last file deletion
    // if the field is not mandatory
    if (
      onFormValidation &&
      !block?.mandatory &&
      evidenceSubType?.uploadType === UploadType.MULTIPLE &&
      uploadedFiles?.length === 1
    ) {
      onFormValidation(true);
    }

    const updatesFiles = uploadedFiles.map((file) => {
      if (file.id === id) {
        return {
          ...file,
          status: FileStatus.DELETING,
        };
      }
      return file;
    });
    setUploadedFiles(updatesFiles);
    const file = uploadedFiles.find((file) => file.id === id);
    const deleteRequestData = {
      nomFichier: file?.name ?? '',
      typePiece: block.name,
      typeEmprunteur: getRibOwner(),
    };
    triggerSendDeleteDocument({
      numeroSouscription: souscriptionId ?? '',
      arg: deleteRequestData,
    })
      .then(() => {
        setUploadedFiles((prevFiles) => {
          return prevFiles?.filter((file) => file.id !== id);
        });
        // Deleting previewed images
        setProcessedImages((prevImages) => {
          return prevImages?.map((element) => {
            if (element.id === id) {
              return {
                ...element,
                status: ImageStatus.DELETED,
              };
            }
            return element;
          });
        });
      })
      .catch(() => {});
  };

  const onFileCancelUpload = (id: string) => {
    setUploadedFiles((prevFiles) => {
      return prevFiles?.filter((file) => file.id !== id);
    });
  };

  const [ocrData, setOcrData] = useState<AnalyseDocument>();
  const [triggerSendAnalyseDocument, resultSendAnalyseDocument] =
    useSendAnalyseDocumentMutation();

  const {
    isVisible: isModalVisible,
    isClosable: isModalClosable,
    isClosableBtnDisabled,
    handleClose: handleCloseModal,
    modalContent,
  }: IUseModalOcrOutput = useModalOcr({
    role: personTypes.RoleCd.BORROWER,
    blockName: block.name,
    maxReuploadAttempt: ocrParameters.maxReloadFile,
    handleValidate: async () => {
      // Inform parent
      handleValidate(block);
    },
    handleReupload: () => {
      setOcrData(undefined);
      deleteAllFiles();
    },
    getEvidenceSubTypeCd: () => {
      return '';
    },
    clientHasHisRib,
    isMaxFilesLimitReached,
    setIsMaxFilesLimitReached,
    isNoRibSepa,
    setNoRibSepa,
    onNoRibCancel: () => setClientHasHisRib(true),
    files: uploadedFiles,
    setProcessedImages,
    ocrIsNoSEPA: false,
    setOcrIsNoSEPA: () => {},
    ocrData,
    borrowerType: getRibOwner(),
    ribOwner: ribOwner ? ribOwner : borrowerType, // Ajouter une nouvelle variable qui prend la valeur de borrowerType sans transformation pour ne pas casser le parcours actuel
  });

  const handleValidate = (block: IEvidenceBlock) => {
    onValidate(block);
    setBlockStatus(BlockStatus.VALIDATED);
  };

  useEffect(() => {
    if (resultSendAnalyseDocument?.data) {
      const data = resultSendAnalyseDocument?.data;
      setOcrData(data);
    }
    if (resultSendAnalyseDocument?.error) {
      const error = resultSendAnalyseDocument.error;
      onFailure(error);
    }
  }, [resultSendAnalyseDocument]);

  useEffect(() => {
    setIsAnalyseSending(!!resultSendAnalyseDocument?.isLoading);
  }, [resultSendAnalyseDocument?.isLoading]);

  const onFailure = (error: any) => {
    if (
      ('originalStatus' in error && error?.originalStatus === 401) ||
      ('data' in error && error?.data === 'No authentication')
    ) {
      setOcrData({
        statut: StatusCd.NOT_AUTHENTICATED,
      });
    } else if (
      ('name' in error && error?.name == StatusCd.ABORTED) ||
      ('data' in error && error?.status === 504)
    ) {
      setOcrData({
        type_piece: block.name,
        soustype_piece: evidenceSubType?.id,
        statut: StatusCd.TIMEOUT,
      });
    } else if ('data' in error && Array.isArray(error?.data)) {
      if (
        [
          StatusCd.ANALYSE_OCR_KO,
          StatusCd.INITIALISATION_OCR_KO,
          StatusCd.LECTURE_COS_KO,
          StatusCd.SUPPRESSION_COS_KO,
          StatusCd.TYPE_PIECE_INVALIDE,
          StatusCd.SOUSTYPE_PIECE_INVALIDE,
          StatusCd.TYPE_EMPRUNTEUR_INVALIDE,
          StatusCd.BANK_INFORMATIONS_API_CONTROLE_BIC_IBAN,
          StatusCd.CONTROLE_SEPA_KO,
          StatusCd.CONTROLE_IBAN_KO,
          StatusCd.CONTROLE_BIC_KO,
        ].includes(error.data[0].error)
      ) {
        setOcrData({
          statut: StatusCd.ANALYZE_KO,
        });
      } else {
        setOcrData({
          statut: StatusCd.SERVER_ERROR,
        });
      }
    } else {
      setOcrData({
        statut: StatusCd.SERVER_ERROR,
      });
    }
    console.log(error);
  };
  const handleUploadSuccess = async () => {
    if ([EvidenceType.JDOP.toString()].includes(block.name)) {
      handleValidate(block);
      return;
    }
    const queryParams = {
      numeroSouscription: souscriptionId ?? '',
      arg: {
        soustypePiece: evidenceSubType?.id ?? '',
        typeEmprunteur: getRibOwner(),
        typePiece: block.name,
      },
    };
    triggerSendAnalyseDocument(queryParams);
  };
  const configuration = useAppSelector(selectConfiguration);
  const { souscriptionId } = {
    ...configuration,
  };

  // Gestion modale information parcours sans RIB Mandat SEPA
  const displayNoRibSepa = (): void => {
    setNoRibSepa(true);
  };

  const handleFileUpload = async (files: File[], type?: string) => {
    files.forEach(async (uploadingFile) => {
      const file: IFile = {
        id: getUniqueId(),
        name: uploadingFile.name,
        file: uploadingFile,
        status: FileStatus.PENDING,
        progress: 0,
        error: '',
        type,
      };
      setUploadedFiles([...uploadedFiles, file]);
      uploadFile(
        file,
        setProcessedImage,
        sendDocument,
        updateFile,
        setFileUploadSuccess,
        setFileUploadFailure,
        onFailure,
        ocrParameters,
        evidenceSubType
      );
    });
  };

  const sendDocument = async (file: File, id: string) => {
    const formData = new FormData();
    formData.append('fichier', file, file.name);
    const typeEmprunteur =
      block.name === EvidenceType.JBAN && ribOwner
        ? getRibOwner()
        : borrowerType;
    const uploadRequestData = {
      soustypePiece: evidenceSubType?.id ?? '',
      typeEmprunteur,
      typePiece: block.name,
    };
    return triggerSendDocument({
      numero_souscription: souscriptionId ?? '',
      arg: uploadRequestData,
      formData,
    }).unwrap();
  };

  const updateFile = (file: File, id: string): File => {
    const uniqueName = getUniqueName(file.name, uploadedFiles, id);
    const fileToUpdate = getRenamedFile(file, uniqueName);
    setUploadedFiles((prevFiles) => {
      return prevFiles?.map((element) => {
        if (element.id === id) {
          return {
            ...element,
            status: FileStatus.RENAMING,
            name: uniqueName,
            file: fileToUpdate,
          };
        }
        return element;
      });
    });
    return fileToUpdate;
  };

  const setFileUploadSuccess = (id: string) => {
    setUploadedFiles((prevFiles) => {
      return prevFiles?.map((element) => {
        if (element.id === id) {
          return {
            ...element,
            status: FileStatus.UPLOADED,
            progress: 100,
            error: '',
          };
        }
        return element;
      });
    });
  };

  const deleteAllFiles = () => {
    uploadedFiles.forEach(async (element) => {
      if (element?.status === FileStatus.UPLOADED) {
        const deleteRequestData = {
          nomFichier: element?.name ?? '',
          typePiece: block.name,
          typeEmprunteur: getRibOwner(),
        };
        await triggerDeleteDocumentCancel({
          numeroSouscription: souscriptionId ?? '',
          arg: deleteRequestData,
        });
      }
    });
    setUploadedFiles([]);
  };

  const setFileUploadFailure = (id: string, error: any) => {
    setUploadedFiles((prevFiles) => {
      return prevFiles?.map((element) => {
        if (element.id === id) {
          return {
            ...element,
            status: FileStatus.FAILED,
            progress: 100,
            error,
          };
        }
        return element;
      });
    });
  };

  const setProcessedImage = (image: IImagesFile) => {
    setProcessedImages((prevProcessedImages) => {
      return addImage(prevProcessedImages, image);
    });
  };

  const getRightIcon = (blockStatus?: BlockStatus): React.FC | undefined => {
    if (!isExpandable) {
      return undefined;
    }
    if (isEnded) {
      switch (blockStatus) {
        case BlockStatus.VALIDATED:
          return StyledSentIcon;
        case BlockStatus.CANCELED:
          return CrossCircleOutlinesIcon;
        default:
          return undefined;
      }
    }
    return ChevronDownIcon;
  };

  const getLeftIcon = (): React.FC | undefined => {
    if (!isMobileScreenSize() || !block?.name) {
      return undefined;
    }
    switch (block.name) {
      case EvidenceType.JDID:
        return PersonSymphonyIcon;
      case EvidenceType.JDDO:
        return HomeSymphonyIcon;
      case EvidenceType.JDRE:
        return FinanceSymphonyIcon;
      case EvidenceType.JBAN:
      case EvidenceType.JDOP:
        return FilePayslipSymphonyIcon;
      default:
        break;
    }
    return undefined;
  };

  const roles: IOption[] = [
    { id: personTypes.RoleCd.BORROWER, label: messages.RIB_BORROWER.title },
    { id: personTypes.RoleCd.COBORROWER, label: messages.RIB_COBORROWER.title },
    {
      id: personTypes.RoleCd.COMPTE_JOINT,
      label: messages.RIB_COMPTE_JOINT.title,
    },
  ];

  return (
    <>
      <StyledCard
        hasBorder={isEnded}
        isDisabled={blockStatus === BlockStatus.NOT_VALIDATED && !!ocrKo}
        borderColor={
          blockStatus === BlockStatus.CANCELED
            ? BorderColorType.GREY
            : BorderColorType.GREEN
        }
        onClick={() => {
          onExpanding();
          setIsExpanded(true);
        }}
      >
        <Loader
          isLoading={resultSendAnalyseDocument.isLoading}
          message={
            isMobileScreenSize() ? (
              <>
                <StyledSubtitleUploading>
                  {messagesDocuments.TITLE_UPLOADING_JUSTIFICATIF_MOBILE}
                </StyledSubtitleUploading>
                <TextParagraph>
                  {messagesDocuments.TEXT_UPLOADING_JUSTIFICATIF_MOBILE}
                </TextParagraph>
              </>
            ) : (
              messages.documentTransmission.title
            )
          }
          isTextInline={!isMobileScreenSize()}
        >
          <CardHeader
            RightIcon={getRightIcon(blockStatus)}
            LeftIcon={getLeftIcon()}
          >
            <WrapperCardDocumentSent>
              <StyledSubtitle>
                {messages[block.name as keyof typeof messages].title}
                {block.name === EvidenceType.JBAN && (
                  <InformationTooltip
                    isToolTipDisabled={true}
                    onClickOpenModal={displayNoRibSepa}
                  />
                )}
              </StyledSubtitle>
              {block.mandatory && (
                <StyledMandatory>{messages.mandatory.title}</StyledMandatory>
              )}
            </WrapperCardDocumentSent>
            {blockStatus === BlockStatus.VALIDATED && (
              <WrapperSent>
                {!isMobileScreenSize() && (
                  <StyledSentText>
                    {messages.DOCUMENT_SENT.title}
                  </StyledSentText>
                )}
              </WrapperSent>
            )}
            {blockStatus === BlockStatus.CANCELED && (
              <WrapperSent>
                {!isMobileScreenSize() && (
                  <StyledSentText>
                    {messages.DOCUMENT_NOT_SENT.title}
                  </StyledSentText>
                )}
              </WrapperSent>
            )}
          </CardHeader>

          {!isClosed && (
            <Card.Body isCollapsed={isEnded}>
              {block.evidenceTypes.length > 1 && (
                <WrapperSelect>
                  <Select
                    name={`evidence-type-${block.name}`}
                    placeholder={
                      messages[block.name as keyof typeof messages].placeholder
                    }
                    value={evidenceSubType?.id || ''}
                    onChange={handleChange}
                    options={block.evidenceTypes.map((item) => ({
                      id: item.id,
                      label: item.label || messages.defaultEvidenceLabel.title,
                    }))}
                    isDisabled={block.evidenceTypes.length === 1}
                  />
                </WrapperSelect>
              )}
              {block.name === EvidenceType.JBAN && hasCoborrower() && (
                <WrapperSelect>
                  <Select
                    name={`rib-owner`}
                    placeholder={messages.RIB_OWNER.placeholder}
                    value={ribOwner ?? ''}
                    onChange={handleChangeJBAN}
                    options={roles}
                  />
                </WrapperSelect>
              )}
              {block.name === EvidenceType.JBAN && ribOwner && (
                <WrapperCheckBox>
                  <WrapperCheckBoxHasHisRib>
                    <>
                      {messages.labelCustomerHasHisRib.title}
                      <BlockRadio
                        onClick={() => setClientHasHisRib(!clientHasHisRib)}
                      >
                        <BlockRadioButton>
                          <RadioButton
                            name="withRib"
                            id="rib"
                            checked={clientHasHisRib}
                          >
                            Oui
                          </RadioButton>
                        </BlockRadioButton>
                        <BlockRadioButton>
                          <RadioButton
                            name="withoutRib"
                            id="rib"
                            checked={!clientHasHisRib}
                          >
                            Non
                          </RadioButton>
                        </BlockRadioButton>
                      </BlockRadio>
                    </>
                  </WrapperCheckBoxHasHisRib>
                </WrapperCheckBox>
              )}
              {hasError && (
                <Modal isVisible={true}>
                  <GenericError
                    redirectToPopHome={() => {}}
                    willDispatchError={false}
                  />
                </Modal>
              )}
              {((evidenceSubType && block.name != EvidenceType.JBAN) ||
                (evidenceSubType &&
                  block.name === EvidenceType.JBAN &&
                  ribOwner)) && (
                <EvidenceUpload
                  uploadType={evidenceSubType.uploadType}
                  maxMultipleFiles={block.maxMultipleFiles ?? 4}
                  onMaxFilesLimitReached={() => console.log(1)}
                  onFilesUpload={handleFileUpload}
                  onFileDelete={(id) => onFileDelete(id)}
                  onCancelFileUpload={onFileCancelUpload}
                  files={uploadedFiles}
                  maxPdfSize={
                    block.name === EvidenceType.JDOP
                      ? ocrParameters?.blocks?.blocksCommande?.maxPdfSize ??
                        ocrParameters?.maxPdfSize
                      : ocrParameters?.maxPdfSize
                  }
                  maxFileSize={ocrParameters.maxFileSize}
                  onUploadSuccess={handleUploadSuccess}
                  evidenceSubType={evidenceSubType?.id}
                  processedImages={processedImages}
                  borrowerType={getRibOwner()}
                  evidenceType={block.name}
                  isAnalyseSending={isAnalyseSending}
                />
              )}
              <Modal
                isVisible={
                  isModalVisible &&
                  ocrData?.statut !== StatusCd.NOT_AUTHENTICATED
                }
                isClosable={isModalClosable}
                isClosableBtnDisabled={isClosableBtnDisabled}
                handleCloseModal={handleCloseModal}
              >
                {modalContent}
              </Modal>
            </Card.Body>
          )}
        </Loader>
      </StyledCard>
    </>
  );
};
export default BlockOcr;
