import { useLazyGetControleEmailDqeQuery } from '@store';
import { RefObject, useEffect, useState } from 'react';
import usePersistingState from './usePersistingState';
import { isEmailValide } from '@utils';
import { ModalDqe } from '@components';

export interface DqeModalProps {
  setIsToBeCorrected?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDqeErrorModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  emailInputRef?: () => RefObject<HTMLInputElement> | null;
}

export interface CheckEmailValideAndDqeProps {
  newMailToTest: string | number | null | undefined;
  emailInputRef?: RefObject<HTMLInputElement> | null;
}

const useEmailControle = () => {
  const [triggerSendControlEmailDqe, resultSendControlEmailDqe] =
    useLazyGetControleEmailDqeQuery();
  const [lastDqeEmailTested, setLastDqeEmailTested, getLastDqeEmailTested] =
    usePersistingState<string>('lastDqeEmailTested', '');
  const [
    isDqeErrorModalVisible,
    setIsDqeErrorModalVisible,
    getIsDqeErrorModalVisible,
  ] = usePersistingState<boolean>('isDqeErrorModalVisible', false);

  const [isToBeCorrected, setIsToBeCorrected, getIsToBeCorrected] =
    usePersistingState<boolean>('dqeEmail-isToBeCorrected', false);

  const [emailInputRef, setEmailInputRef] =
    useState<RefObject<HTMLInputElement> | null>(null);
  const getEmailInputRef = () => emailInputRef;

  const DqeModal: React.FC<DqeModalProps> = ({
    setIsToBeCorrected: setIsToBeCorrectedParam,
    setIsDqeErrorModalVisible: setIsDqeErrorModalVisibleParam,
    emailInputRef: emailInputRefParam,
  }: DqeModalProps) => (
    <ModalDqe
      emailInputRef={emailInputRefParam ?? getEmailInputRef}
      setIsToBeCorrected={setIsToBeCorrectedParam ?? setIsToBeCorrected}
      setIsDqeErrorModalVisible={
        setIsDqeErrorModalVisibleParam ?? setIsDqeErrorModalVisible
      }
      getIsDqeErrorModalVisible={getIsDqeErrorModalVisible}
      getLastDqeEmailTested={getLastDqeEmailTested}
    />
  );

  useEffect(() => {
    if (!resultSendControlEmailDqe?.data) {
      return;
    }
    if (resultSendControlEmailDqe?.data?.message === 'email non reconnu') {
      setIsDqeErrorModalVisible(true);
    }
  }, [resultSendControlEmailDqe?.data]);

  const checkEmailValideAndDqe = ({
    newMailToTest,
    emailInputRef = null,
  }: CheckEmailValideAndDqeProps) => {
    setEmailInputRef(emailInputRef);

    if (
      !newMailToTest ||
      typeof newMailToTest !== 'string' ||
      newMailToTest.length === 0 ||
      !isEmailValide(newMailToTest)
    ) {
      return;
    }
    setIsToBeCorrected(false);
    if (newMailToTest === getLastDqeEmailTested()) {
      return;
    }
    setLastDqeEmailTested(newMailToTest);
    triggerSendControlEmailDqe(newMailToTest, true);
  };

  const isEmailValideOrToBeCorrected = (mail: string) => {
    return isEmailValide(mail) && !getIsToBeCorrected();
  };

  return {
    isResponseDqeLoading: resultSendControlEmailDqe?.isLoading,
    getIsDqeErrorModalVisible,
    setIsDqeErrorModalVisible,
    lastDqeEmailTested,
    getLastDqeEmailTested,
    checkEmailValideAndDqe,
    isEmailValideOrToBeCorrected,
    ModalDqe: DqeModal,
  };
};

export default useEmailControle;
