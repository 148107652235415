import { ButtonsFooter, Centering, MentionLegal, Form } from '@components';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import {
  IEmprunteurRequest,
  ICoEmprunteurRequest,
  IBankInformationsRequest,
  EvidenceType,
} from '@types';
import * as messages from './messages';
import {
  selectConfiguration,
  updatePersonEmprunteur,
  useAppDispatch,
  useAppSelector,
  useSendEmprunteurMutation,
  personTypes,
  updatePersonCoEmprunteur,
  useSendCoEmprunteurMutation,
  useSendBankInformationsMutation,
  updateBankDetails,
  selectBankDetails,
  selectPerson,
  useLazyGetEmprunteurInformationsQuery,
  useLazyGetCoEmprunteurInformationsQuery,
  useLazyGetBankInformationsQuery,
} from '@store';
import InformationsIdentity from './InformationsIdentity/InformationsIdentity';
import InformationsBirthLocation from './InformationsBirthLocation/InformationsBirthLocation';
import HousingSituation from './HousingSituation';
import InformationsId from './InformationsId';
import FinancialSituation from './FinancialSituation';
import FamilySituation from './FamilySituation';
import Contact from './Contact';
import BankingInformation from './BankingInformation';
import ProfessionalSituation from './ProfessionalSituation';
import ContactCoBorrower from './ContactCoBorrower/ContactCoBorrower';
import { useEffect, useState } from 'react';
import {
  buildCoEmprunteurRequestUtils,
  buildCoEmprunteurStoreUtils,
  buildEmprunteurRequestUtils,
  buildEmprunteurStoreUtils,
  buildFullBankInformationsRequestUtils,
  buildPartialBankInformationsRequestUtils,
  buildEmprunteurBackDataToStoreUtils,
} from './InformationsUtils';
import { useBorrowers, usePersistingState, useEmailControle } from '@hooks';
import { StyledBankIcon, StyledPersonIcon, StyledWalletIcon } from './styles';

export const useInformationsReAuth = () => {
  const [, setCurrentFormId] = usePersistingState(
    'informations-current-form-id',
    1
  );
  return {
    reauth: (forceReAuth = true) => {
      if (!forceReAuth) {
        return;
      }
      setCurrentFormId(1);
    },
  };
};

const Informations: React.FC = () => {
  const [currentFormId, setCurrentFormId] = usePersistingState(
    'informations-current-form-id',
    1
  );

  const [maxVisibleFormId, setMaxVisibleFormId] = usePersistingState(
    'informations-max-visible-form-id',
    1
  );

  const [sendEmprunteur, responseEmprunteur] = useSendEmprunteurMutation();
  const [sendCoEmprunteur, responseCoEmprunteur] =
    useSendCoEmprunteurMutation();
  const [triggerBankInformations, resultBankInformations] =
    useLazyGetBankInformationsQuery();
  const [triggerEmprunteurInformations, resultEmprunteurInformations] =
    useLazyGetEmprunteurInformationsQuery();
  const [triggerCoEmprunteurInformations, resultCoEmprunteurInformations] =
    useLazyGetCoEmprunteurInformationsQuery();

  const [sendBankInformations, responseBankInformations] =
    useSendBankInformationsMutation();

  const dispatch = useAppDispatch();

  const configuration = useAppSelector(selectConfiguration);

  const { souscriptionId, mentionLegaleEmprunteur } = {
    ...configuration,
  };

  const [addressCity, setAddressCity] = usePersistingState<string>(
    'informations-address-city',
    ''
  );

  const [errorRib, setErrorRib] = useState<string | null>(null);
  const [allValues, setAllValues] = useState<FieldValues | null>(null);

  const bankDetails = useAppSelector(selectBankDetails);
  const persons = useAppSelector(selectPerson);
  const { hasCoborrower } = useBorrowers();
  // Identifie le nombre d'emprunteurs au moment de la saisie des formulaires
  const [withCoborrower, setWithCoborrower] = usePersistingState<
    boolean | null
  >('informations-with-coborrower', null);
  const {
    isResponseDqeLoading,
    checkEmailValideAndDqe,
    isEmailValideOrToBeCorrected,
    ModalDqe,
  } = useEmailControle();

  // Mise à jour depuis le premier bloc si le nombre d'emprunteurs a changé (suite à un retour sur la page offre)
  if (
    withCoborrower !== null &&
    withCoborrower !== hasCoborrower() &&
    currentFormId !== 1
  ) {
    setCurrentFormId(1);
    setMaxVisibleFormId(1);
  }

  useEffect(() => {
    if (!configuration?.souscriptionId) {
      return;
    }
    if (!persons?.emprunteur?.identity) {
      triggerEmprunteurInformations(configuration?.souscriptionId ?? '');
    }
    if (!persons?.coemprunteur?.identity) {
      triggerCoEmprunteurInformations(configuration?.souscriptionId ?? '');
    }
    if (!bankDetails?.bic || !bankDetails?.iban || !bankDetails?.accountOwner) {
      triggerBankInformations(configuration?.souscriptionId ?? '');
    }
  }, []);

  useEffect(() => {
    if (Object.keys(resultEmprunteurInformations.data ?? {}).length > 0) {
      const informationEmprunteur = resultEmprunteurInformations?.data;
      dispatch(
        updatePersonEmprunteur(
          buildEmprunteurBackDataToStoreUtils(informationEmprunteur)
        )
      );
    }
  }, [resultEmprunteurInformations]);

  useEffect(() => {
    if (Object.keys(resultCoEmprunteurInformations.data ?? {}).length === 0) {
      return;
    }
    dispatch(
      updatePersonCoEmprunteur(
        buildEmprunteurBackDataToStoreUtils(
          resultCoEmprunteurInformations?.data
        )
      )
    );
  }, [resultCoEmprunteurInformations]);

  useEffect(() => {
    if (Object.keys(resultBankInformations.data ?? {}).length === 0) {
      return;
    }
    const bankInformations = resultBankInformations?.data;
    dispatch(
      updateBankDetails({
        bic: bankInformations?.bic,
        iban: bankInformations?.iban,
        accountOwner: bankInformations?.proprietaire_compte,
        accountOpeningDt: bankInformations?.date_ouverture_compte,
      })
    );
  }, [resultBankInformations]);

  useEffect(() => {
    if (
      responseBankInformations.isUninitialized ||
      responseBankInformations.isLoading
    ) {
      return;
    }
    const dataError =
      (responseBankInformations?.error as { data: any }).data ?? [];
    if ((dataError ?? [])[0]) {
      setErrorRib(dataError[0]?.error);
      setCurrentFormId(5);
    }
  }, [responseBankInformations.error]);

  const updateFormsExpandedAndVisible = (formId: number) => {
    if (withCoborrower !== hasCoborrower()) {
      setWithCoborrower(hasCoborrower());
    }
    let step = 1;
    // Si on est arrivé à la fin
    if (formId > 5) {
      return;
    }
    // S'il on est en parcours monoemp - on passe les blocs coemp
    if (formId === 2 && !hasCoborrower()) {
      step = 3;
    }
    // Si on valide le dernier bloc actuellement visible
    if (formId === maxVisibleFormId) {
      setMaxVisibleFormId(formId + step);
    }
    setCurrentFormId(formId + step);
  };

  const updateAllValues = (newValues: FieldValues) => {
    const values = allValues;
    setAllValues({ ...values, ...newValues });
  };

  const onSubmitIdentiteEmprunteur = (methods: UseFormReturn) => {
    updateFormsExpandedAndVisible(1);
    updateAllValues(methods.getValues());
    dispatch(
      updatePersonEmprunteur(
        buildEmprunteurStoreUtils(buildEmprunteurRequest(methods.getValues()))
      )
    );
  };

  const onSubmitSituationFinanciereEmprunteur = (methods: UseFormReturn) => {
    updateFormsExpandedAndVisible(2);
    const request = buildEmprunteurRequest({
      ...allValues,
      ...methods.getValues(),
    });
    sendEmprunteur(request);
    updateAllValues(methods.getValues());
  };

  const onSubmitIdentiteCoEmprunteur = (methods: UseFormReturn) => {
    updateFormsExpandedAndVisible(3);
    updateAllValues(methods.getValues());
    dispatch(
      updatePersonCoEmprunteur(
        buildCoEmprunteurStoreUtils(
          buildCoEmprunteurRequest(methods.getValues())
        )
      )
    );
  };

  const onSubmitSituationFinanciereCoEmprunteur = (methods: UseFormReturn) => {
    updateFormsExpandedAndVisible(4);
    const request = buildCoEmprunteurRequest({
      ...allValues,
      ...methods.getValues(),
    });
    sendCoEmprunteur(request);
    updateAllValues(methods.getValues());
    dispatch(
      updatePersonCoEmprunteur(
        buildCoEmprunteurStoreUtils(
          buildCoEmprunteurRequest(methods.getValues())
        )
      )
    );
  };

  const onSubmitBankInformations = (methods: UseFormReturn) => {
    updateFormsExpandedAndVisible(5);
    const bankInformationsRequest = buildBankInformationsRequest(
      methods.getValues()
    );
    sendBankInformations(bankInformationsRequest);
    updateAllValues(methods.getValues());
    dispatch(
      updateBankDetails({
        bic: bankInformationsRequest?.bankInformations?.bic ?? bankDetails.bic,
        iban:
          bankInformationsRequest?.bankInformations?.iban ?? bankDetails.iban,
        accountOwner:
          bankInformationsRequest?.bankInformations?.proprietaire_compte ??
          bankDetails.accountOwner,
        accountOpeningDt:
          bankInformationsRequest?.bankInformations?.date_ouverture_compte,
      })
    );
  };

  const buildEmprunteurRequest = (submitData: any): IEmprunteurRequest => {
    return {
      numero_souscription: souscriptionId ?? '',
      emprunteur: buildEmprunteurRequestUtils(submitData, addressCity),
    };
  };

  const buildCoEmprunteurRequest = (submitData: any): ICoEmprunteurRequest => {
    return {
      numero_souscription: souscriptionId ?? '',
      coemprunteur: buildCoEmprunteurRequestUtils(submitData),
    };
  };

  const shouldSendRIBInformations = (): boolean => {
    return (
      !bankDetails?.accountOwner ||
      !bankDetails?.bic ||
      !bankDetails?.iban ||
      (!persons?.emprunteur?.documents?.find(
        (document) => document?.evidenceTypeCd === EvidenceType.JBAN
      ) &&
        !persons?.coemprunteur?.documents?.find(
          (document) => document?.evidenceTypeCd === EvidenceType.JBAN
        ))
    );
  };

  const buildBankInformationsRequest = (
    submitData: any
  ): IBankInformationsRequest => {
    return {
      numero_souscription: souscriptionId ?? '',
      bankInformations: shouldSendRIBInformations()
        ? buildFullBankInformationsRequestUtils(submitData)
        : buildPartialBankInformationsRequestUtils(submitData),
    };
  };

  const isBlocVisible = (id: number) =>
    maxVisibleFormId > id - 1 && currentFormId > id - 1;

  return (
    <>
      <Centering>
        <Form
          titleIcon={<StyledPersonIcon />}
          title={messages.CONTAINER_INFORMATIONS_IDENTITY_BORROWER_TITLE}
          onSubmit={onSubmitIdentiteEmprunteur}
          isSubmitLoading={isResponseDqeLoading}
          expand={currentFormId === 1}
          onClickModify={() => setCurrentFormId(1)}
          scrollToTop={false}
        >
          <InformationsIdentity role={personTypes.RoleCd.BORROWER} />
          <InformationsBirthLocation role={personTypes.RoleCd.BORROWER} />
          <InformationsId role={personTypes.RoleCd.BORROWER} />
          <Contact
            setAddressCity={setAddressCity}
            checkEmailValideAndDqe={checkEmailValideAndDqe}
            isEmailValideOrToBeCorrected={isEmailValideOrToBeCorrected}
          />
          <FamilySituation role={personTypes.RoleCd.BORROWER} />
          <HousingSituation role={personTypes.RoleCd.BORROWER} />
          <ProfessionalSituation role={personTypes.RoleCd.BORROWER} />
        </Form>
        <Form
          titleIcon={<StyledWalletIcon />}
          title={messages.FINANCIAL_SITUATION_BORROWER_TITLE}
          onSubmit={onSubmitSituationFinanciereEmprunteur}
          expand={currentFormId === 2}
          isVisible={isBlocVisible(2)}
          onClickModify={() => setCurrentFormId(2)}
        >
          <FinancialSituation role={personTypes.RoleCd.BORROWER} />
        </Form>

        {/* Bloc coemprunteur */}
        {hasCoborrower() && (
          <>
            <Form
              onSubmit={onSubmitIdentiteCoEmprunteur}
              isSubmitLoading={isResponseDqeLoading}
              titleIcon={<StyledPersonIcon />}
              title={messages.CONTAINER_INFORMATIONS_IDENTITY_COBORROWER_TITLE}
              expand={currentFormId === 3}
              isVisible={isBlocVisible(3)}
              onClickModify={() => setCurrentFormId(3)}
            >
              <InformationsIdentity role={personTypes.RoleCd.COBORROWER} />
              <InformationsBirthLocation role={personTypes.RoleCd.COBORROWER} />
              <InformationsId role={personTypes.RoleCd.COBORROWER} />
              {/* On a créé un composant "Contact" spécifique pour le
                coemprunteur, car trop différent de celui de l'emprunteur. */}
              <ContactCoBorrower
                checkEmailValideAndDqe={checkEmailValideAndDqe}
                isEmailValideOrToBeCorrected={isEmailValideOrToBeCorrected}
              />
              <ProfessionalSituation role={personTypes.RoleCd.COBORROWER} />
            </Form>
            <Form
              titleIcon={<StyledWalletIcon />}
              title={messages.FINANCIAL_SITUATION_COBORROWER_TITLE}
              onSubmit={onSubmitSituationFinanciereCoEmprunteur}
              expand={currentFormId === 4}
              isVisible={isBlocVisible(4)}
              onClickModify={() => setCurrentFormId(4)}
              isSubmitLoading={responseEmprunteur?.isLoading}
            >
              <FinancialSituation role={personTypes.RoleCd.COBORROWER} />
            </Form>
          </>
        )}

        <Form
          titleIcon={<StyledBankIcon />}
          title={messages.BANKING_INFORMATIONS_TITLE}
          onSubmit={onSubmitBankInformations}
          expand={
            currentFormId === 5 ||
            responseBankInformations?.isLoading ||
            responseBankInformations?.status === 'rejected'
          }
          isVisible={isBlocVisible(5)}
          onClickModify={() => setCurrentFormId(5)}
          isSubmitLoading={
            responseEmprunteur?.isLoading ||
            (hasCoborrower() && responseCoEmprunteur?.isLoading) ||
            responseBankInformations?.isLoading
          }
        >
          <BankingInformation
            role={personTypes.RoleCd.BORROWER}
            errorRib={errorRib}
            setErrorRib={setErrorRib}
          />
        </Form>

        <ModalDqe />
        <ButtonsFooter
          isNextVisible={
            currentFormId === 6 &&
            !responseBankInformations?.isLoading &&
            responseBankInformations?.status !== 'rejected'
          }
          isNextDisabled={currentFormId < 6 || !!errorRib}
          isLoading={
            isBlocVisible(5) &&
            (responseEmprunteur?.isLoading ||
              (hasCoborrower() && responseCoEmprunteur?.isLoading) ||
              responseBankInformations?.isLoading)
          }
        />
        <MentionLegal htmlContent={mentionLegaleEmprunteur} />
      </Centering>
    </>
  );
};

export default Informations;
